import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { isSalonzone } from '../utils';

import favicon16 from '../images/favicon16.png';
import favicon32 from '../images/favicon32.png';
import favicon64 from '../images/favicon64.png';
import scFavicon16 from '../images/sc-favicon16.png';
import scFavicon32 from '../images/sc-favicon32.png';
import scFavicon64 from '../images/sc-favicon64.png';

const SEO = ({ description, lang, meta, title, thumbnailPreview }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        title ? `%s | ${site.siteMetadata.title}` : site.siteMetadata.title
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: thumbnailPreview,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: isSalonzone ? `${scFavicon16}` : `${favicon16}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: isSalonzone ? `${scFavicon32}` : `${favicon32}`,
        },
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: isSalonzone ? `${scFavicon64}` : `${favicon64}`,
        },
      ]}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
